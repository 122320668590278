<template>
    <div id="apipe-cloud-course-page">
      <top-nav-new />
      <div class="apipe-cloud-course-container-madeSolution">
        <div class="pc-banner d-none d-lg-block">
          <div class="container" style="text-align: left">
            <h1 style="margin-bottom: 15px; font-size: 42px; font-weight: 700">
                优制云客户关系管理CRM
            </h1>
            <p class="tit">
                   优制云CRM，专注于解决客户关系管理中的复杂问题。本系统集销售目标管理、商机深度挖掘、<br>资源优化配置、销售活动规划、合同订单自动化处理、客户资料精细化管理、精准销售报价<br>生成、销售对账透明化、回款计划严谨制定及应收开票自动化等功能于一体。通过高度集成的CRM<br>解决方案，助力企业实现销售流程的专业化、规范化与智能化，提升客户满意度与忠诚度，为中<br>小微企业的稳健发展提供坚实支撑。
            </p>
          </div>
          <MessageInfo />
        </div>
  
        <div class="mobile-banner d-block d-lg-none">
          <div class="container" style="text-align: left">
            <div class="title">优制云客户关系管理CRM</div>
            <div class="titwrap">
              <p class="tit">
                助力企业实现销售流程的专业化、规范化与智能化，提升客户满意度与忠诚度，为中小微企业的稳健发展提供坚实支撑。
              </p>
            </div>

          </div>
        </div>
  
        <!-- 行业痛点 -->
        <div class="pc-industry d-none d-lg-block">
          <div class="pro-tit">行业痛点</div>
          <div class="container text-center">
            <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/crm/hangye1.png" alt="" />
                  <h6>客户信息分散</h6>
                  <div class="des">
                    <div>客户资料不集中，难以全面了解</div>
                    <div>客户需求</div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/crm/hangye2.png" alt="" />
                  <h6>销售管理缺失</h6>
                  <div class="des">
                    <div>销售机会跟踪不力，转化率低</div>
 
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/crm/hangye3.png" alt="" />
                  <h6> 客户服务响应慢</h6>
                  <div class="des">
                    <div>客户服务流程不标准化，响应速</div>
                    <div>度慢</div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/crm/hangye4.png" alt="" />
                  <h6>客户满意度低</h6>
                  <div class="des">
                    <div>缺乏有效的客户反馈机制，无法</div>
                    <div>及时改进服务</div>
                  </div>
                </div>
              </div>
            </div>
  
            <div class="row"></div>
          </div>
        </div>
        <div class="mobile-industry d-block d-lg-none">
          <div class="pro-tit">行业痛点</div>
          <div class="container text-center">
            <div class="row row-cols-2 row-cols-lg-4 g-2 g-lg-3">
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/isc/hangye1.png" alt="" />
                  <h6>客户信息分散</h6>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/isc/hangye2.png" alt="" />
                  <h6>销售管理缺失</h6>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/isc/hangye3.png" alt="" />
                  <h6>客户服务响应慢</h6>
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/isc/hangye4.png" alt="" />
                  <h6>客户满意度低</h6>
                </div>
              </div>
            </div>
  
            <div class="row"></div>
          </div>
        </div>
  
        <!-- 解决方案 -->
        <div class="container text-center">
          <div class="pro-tit d-none d-lg-block" style="font-size: 36px">
            解决方案
          </div>
          <div
            class="pro-tit d-block d-lg-none"
            style="font-size: 20px; font-weight: 700; margin-bottom: 20px"
          >
            解决方案
          </div>
          <img
            style="width: 100%"
            src="@/assets/newSite/crm/solution.png"
            alt=""
          />
        </div>
  
        <!-- 产品能力 -->
        <div class="pc-pro-power d-none d-lg-block">
          <div class="pro-tit" style="font-size: 36px">产品能力</div>
          <div>
            <div class="tabs container text-center">
              <div
                :class="['items', tbx == index ? 'active' : '']"
                v-for="(item, index) in tabListNew"
                :key="index"
                @click="handleTab(index)"
              >
                <img :src="item.img1" />
                <div class="tit">{{ item.tit }}</div>
              </div>
            </div>
            <div class="tab-cot">
              <div class="container text-center">
                <div class="row" v-show="tbx == 0">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">
                        线索管理，线索转化
                    </div>
                    <div class="mcot">
                      <div>
                        线索录入，分配，转交，转化等，提升线索质量及转化率；并支持多渠道方式
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/crm/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 1">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">客户资料，转化，跟进，商机等</div>
                    <div class="mcot">
                      <div>
                        对客户进行360度全方位画像，维护客户资料完整性，准确性
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/crm/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 2">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">商机资料，转化项目，跟进等</div>
                    <div class="mcot">
                      <div>
                        精确剖析客户需求，对需求铺开分解与之对应的解决方案及应对措施，同时跟进商机计划步骤，鉴别及快速精准决策
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/crm/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 3">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">报价资料，项目，商机报价
                    </div>
                    <div class="mcot">
                      <div>
                        因市场变化而灵活，多方式组合式报价，支持选配方案
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/crm/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 4">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">
                        合同模板，审批等
                    </div>
                    <div class="mcot">
                      <div>
                        因销售产品不同适配不同销售合同模板，灵活快速准确
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/crm/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 5">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">
                        客户订单审核，下达同步等
                    </div>
                    <div class="mcot">
                      <div>
                        因客户需求高效下订单，并集成到ERP系统中销售订单，根据销售订单交货日期展开企业经营活动，协调人，财，物，产，供等调动，高质量，高效率交付
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/crm/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 6">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">
                        项目制开票，流程审批，下推下达等
                    </div>
                    <div class="mcot">
                      <div>
                        以业务流程为导向实现业财一体化，开票申请，审批多节点，高效执行开票流程完结，进行下一步财务工作开展
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/crm/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 7">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">
                        回款计划执行，回款等
                    </div>
                    <div class="mcot">
                      <div>
                        以业务流程为导向实现业财一体化，制定收款计划，执行，核销等一系统财务流程，核算盈亏状况
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/crm/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
                <div class="row" v-show="tbx == 8">
                  <div class="col-xs-12 col-md-6 lf">
                    <div class="mtit">
                        销售业绩报表，客户报表，商机报表，销售漏斗报表等
                    </div>
                    <div class="mcot">
                      <div>
                        提供数据分析，统计，挖掘等工具平台，让客户自行根据所需报表配置化发布等
                      </div>
                    </div>
                    <div style="text-align: left">
                      <el-button
                        @click="handleTest"
                        type="primary"
                        round
                        class="btn btn-primary btn-sm"
                      >
                        免费试用
                      </el-button>
                    </div>
                  </div>
                  <div
                    class="col-xs-12 col-md-6"
                    style="text-align: center; padding: 10px"
                  >
                    <img
                      src="@/assets/newSite/crm/c.png"
                      alt=""
                      style="width: 90%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="mobile-pro-power d-block d-lg-none">
          <h4 class="pro-tit">产品能力</h4>
          <div class="">
            <div class="tabs">
              <div
                :class="['items', tbx == index ? 'active' : '']"
                v-for="(item, index) in tabListNew"
                :key="index"
                @click="handleTab(index)"
              >
                <img :src="item.img1" />
                <div class="tit">{{ item.tit }}</div>
              </div>
            </div>
            <div class="tab-cot">
              <div class="container text-center">
                <div class="row" v-show="tbx == 0">
                  <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                  <h4 style="text-align: left">线索管理，线索转化</h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>线索录入，分配，转交，转化等，提升线索质量及转化率；并支持多渠道方式</div>
                  </div>
                </div>
                <div >
                  <img
                    src="@/assets/newSite/crm/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
                </div>
  
                <div class="row" v-show="tbx == 1">
                  <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                  <h4 style="text-align: left">客户资料，转化，跟进，商机等</h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>对客户进行360度全方位画像，维护客户资料完整性，准确性</div>
                  </div>
                </div>
                <div >
                  <img
                    src="@/assets/newSite/crm/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
                </div>
                <div class="row" v-show="tbx == 2">
                  <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                  <h4 style="text-align: left">商机资料，转化项目，跟进等</h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>精确剖析客户需求，对需求铺开分解与之对应的解决方案及应对措施，同时跟进商机计划步骤，鉴别及快速精准决策</div>
                  </div>
                </div>
                <div >
                  <img
                    src="@/assets/newSite/crm/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
                </div>
                <div class="row" v-show="tbx == 3">
                  <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                  <h4 style="text-align: left">报价资料，项目，商机报价</h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>因市场变化而灵活，多方式组合式报价，支持选配方案</div>
                  </div>
                </div>
                <div >
                  <img
                    src="@/assets/newSite/crm/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
                </div>

                <div class="row" v-show="tbx == 4">
                  <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                  <h4 style="text-align: left">合同模板，审批等</h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>因销售产品不同适配不同销售合同模板，灵活快速准确</div>
                  </div>
                </div>
                <div >
                  <img
                    src="@/assets/newSite/crm/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
                </div>

                <div class="row" v-show="tbx == 5">
                  <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                  <h4 style="text-align: left">客户订单审核，下达同步等</h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>因客户需求高效下订单，并集成到ERP系统中销售订单，根据销售订单交货日期展开企业经营活动，协调人，财，物，产，供等调动，高质量，高效率交付</div>
                  </div>
                </div>
                <div >
                  <img
                    src="@/assets/newSite/crm/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
                </div>

                <div class="row" v-show="tbx == 6">
                  <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                  <h4 style="text-align: left">项目制开票，流程审批，下推下达等</h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>以业务流程为导向实现业财一体化，开票申请，审批多节点，高效执行开票流程完结，进行下一步财务工作开展</div>
                  </div>
                </div>
                <div >
                  <img
                    src="@/assets/newSite/crm/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
                </div>

                <div class="row" v-show="tbx == 7">
                  <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                  <h4 style="text-align: left">回款计划执行，回款等</h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>以业务流程为导向实现业财一体化，制定收款计划，执行，核销等一系统财务流程，核算盈亏状况</div>
                  </div>
                </div>
                <div >
                  <img
                    src="@/assets/newSite/crm/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
                </div>

                <div class="row" v-show="tbx == 8">
                  <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                  <h4 style="text-align: left">销售业绩报表，客户报表，商机报表，销售漏斗报表等</h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>提供数据分析，统计，挖掘等工具平台，让客户自行根据所需报表配置化发布等</div>
                  </div>
                </div>
                <div >
                  <img
                    src="@/assets/newSite/crm/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
                </div>




              </div>
            </div>
          </div>
        </div>
  
        <!-- 产品优势 -->
        <div class="pc-scene d-none d-lg-block">
          <div class="pro-tit d-none d-lg-block">产品优势</div>
          <div class="container text-center">
            <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/crm/ad1.png" alt="" />
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/crm/ad2.png" alt="" />
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/crm/ad3.png" alt="" />
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/crm/ad4.png" alt="" />
                </div>
              </div>
            </div>
  
            <div class="row"></div>
          </div>
        </div>
        <!-- 产品优势 -->
        <div class="mobile-scene d-block d-lg-none">
          <div
            class="pro-tit d-block d-lg-none"
            style="font-size: 20px; font-weight: 700; margin-bottom: 20px"
          >
            产品优势
          </div>
          <div class="container text-center">
            <div class="row row-cols-2 row-cols-lg-4 g-2 g-lg-3">
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/crm/ad1.png" alt="" />
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/crm/ad2.png" alt="" />
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/crm/ad3.png" alt="" />
                </div>
              </div>
              <div class="col">
                <div class="p-3">
                  <img src="@/assets/newSite/crm/ad4.png" alt="" />
                </div>
              </div>
            </div>
  
            <div class="row"></div>
          </div>
        </div>
  
        <!-- 成功案列 -->
        <section
          class="pc-product d-none d-lg-block"
          style="margin: 80px 0 50px 0"
        >
          <div class="container text-center">
            <h1 class="pro-tit" style="margin-bottom: 20px; font-size: 36px">
              成功案例
            </h1>
            <div class="row align-items-start">
              <div
                :class="['col', idx == index ? 'acticv' : '']"
                @click="handleSwitch(index)"
                v-for="(item, index) in tabs"
                :key="index"
              >
                <span>{{ item }}</span>
              </div>
            </div>
          </div>
          <div class="pro-wrap">
            <div
              class="container animate__animated animate__fadeInLeftBig"
              v-show="idx == 0"
            >
              <div class="content" style="display: flex;padding:20px 0 ;">
                <div class="wrap">
                  <h6 style="font-size: 35px">
                    {{caseA.contentTitle}}
                  </h6>
                  <p class="p-content">
                    {{caseA.contentDescription}} 
                  </p>
                  <div>
                    <el-button
                type="primary" size="mini" round
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseA.id}&tit=${caseA.contentTitle}`);
                    }
                  "
                  class="btn btn-primary btn-sm " 
                >
                  查看详情
                </el-button>
                  </div>
                </div>
                <div style="flex: 40%; text-align: center">
                  <img :src="baseImgUrl + caseA.contentImgUrl" width="640px" height="360px"/>
                </div>
              </div>
            </div>
            <div
              class="container animate__animated animate__fadeInLeftBig"
              v-show="idx == 1"
            >
              <div class="content" style="display: flex;padding:20px 0 ;">
                <div class="wrap"  style="flex: 60%">
                  <h6 style="font-size:35px;">{{caseB.contentTitle}}</h6>
                  <p class="p-content">
                    {{caseB.contentDescription}}
                  </p>
                  <div>
                    <el-button
                type="primary" size="mini" round
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseB.id}&tit=${caseB.contentTitle}`);
                    }
                  "
                  class="btn btn-primary btn-sm " 
                >
                  查看详情
                </el-button>
                  </div>
                </div>
                <div style="flex: 40%; text-align: center">
                  <img :src="baseImgUrl + caseB.contentImgUrl" width="640px" height="360px"/>
                </div>
              </div>
            </div>
            <div
              class="container animate__animated animate__fadeInRightBig"
              v-show="idx == 2"
            >
              <div class="content" style="display: flex;padding:20px 0 ;">
                <div class="wrap" style="flex: 60%">
                  <h6  style="font-size:35px;">{{caseC.contentTitle}}</h6>
                  <p class="p-content">
                    {{caseC.contentDescription}}
                  </p>
                  <div>
                    <el-button
                type="primary" size="mini" round
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseC.id}&tit=${caseC.contentTitle}`);
                    }
                  "
                  class="btn btn-primary btn-sm" 
                >
                  查看详情
                </el-button>
                  </div>
                </div>
                <div style="flex: 40%; text-align: center">
                  <img :src="baseImgUrl + caseC.contentImgUrl" width="640px" height="360px"/>
                </div>
              </div>
            </div>
          </div>
        </section>
  
        <section
          class="mobile-product d-block d-lg-none"
          style="margin-top: 40px"
        >
          <div class="container text-center">
            <h3 class="pro-tit">成功案例</h3>
            <div class="row align-items-start">
              <div
                :class="['col', idx == index ? 'acticv' : '']"
                @click="handleSwitch(index)"
                v-for="(item, index) in tabs"
                :key="index"
              >
                <span>{{ item }}</span>
              </div>
            </div>
          </div>
          <div class="pro-wrap">
            <div
              class="container animate__animated animate__fadeInLeftBig"
              v-show="idx == 0"
            >
              <div class="content">
                <div style="flex: 60%">
                  <h6 style="font-weight: 700; margin-bottom: 1rem">
                    {{caseA.contentTitle}}
                  </h6>
                  <p class="p-content">
                    {{caseA.contentDescription}}
                  </p>
                </div>
                <div style="text-align: center; margin-bottom: 20px">
                  <img :src="baseImgUrl + caseA.contentImgUrl"/>
                </div>
                <div>
                <el-button
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseA.id}&tit=${caseA.contentTitle}`);
                    }
                  "
                  round
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  查看详情
                </el-button>
              </div>
              </div>
            </div>
            <div
              class="container animate__animated animate__fadeInLeftBig"
              v-show="idx == 1"
            >
              <div class="content">
                <div style="flex: 60%">
                  <h6 style="font-weight: 700; margin-bottom: 1rem">
                    {{caseB.contentTitle}}
                  </h6>
                  <p class="p-content">
                    {{caseB.contentDescription}}
                  </p>
                </div>
                <div style="text-align: center; margin-bottom: 20px">
                  <img :src="baseImgUrl + caseB.contentImgUrl"/>
                </div>
                <div>
                <el-button
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseB.id}&tit=${caseB.contentTitle}`);
                    }
                  "
                  round
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  查看详情
                </el-button>
              </div>
              </div>
            </div>
            <div
              class="container animate__animated animate__fadeInRightBig"
              v-show="idx == 2"
            >
              <div class="content">
                <div style="flex: 60%">
                  <h6 style="font-weight: 700; margin-bottom: 1rem">
                    {{caseC.contentTitle}}
                  </h6>
                  <p class="p-content">
                    {{caseC.contentDescription}}
                  </p>
                </div>
                <div style="text-align: center; margin-bottom: 20px">
                  <img :src="baseImgUrl + caseC.contentImgUrl"/>
                </div>
                <div>
                <el-button
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseC.id}&tit=${caseC.contentTitle}`);
                    }
                  "
                  round
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  查看详情
                </el-button>
              </div>
              </div>
            </div>
          </div>
        </section>
  
        <right-nav-new class="d-none d-lg-block" />
      </div>
  
      <NewFooter  @handleClickChild="handleClickChild" />
      <MobileFooter ref="mofooter" class="d-block d-lg-none" />
    </div>
  </template>
            
    <script>
  import { Swiper, SwiperSlide } from "vue-awesome-swiper";
  import SwiperCore, { Autoplay } from "swiper";
  SwiperCore.use([Autoplay]);
  import Qs from "qs";
import { Newslist } from "@/api/fetch";
  export default {
    name: "hardware",
    metaInfo() {
    return {
      title: '优制云客户关系管理CRM - 精准客户管理与业务增长必备工具',
      meta: [
        { name: 'keywords', content: '客户关系管理CRM,客户数据分析,商机挖掘,订单管理,营销自动化,SaaS系统,优制云,伟班平台' },
        { name: 'description', content: '优制云工业互联网平台客户关系管理CRM整合了先进的数据分析功能，精准分析客户需求，提供全方位用户画像，智能提供数据分析报表，帮助中小微企业全面管理客户关系，提升客户满意度，推动业务持续增长。' },
        
      ],
    };
  },
    data() {
      return {
        tabs: ["华瀚管廊物业", "数研时空", "润玉空间"],
        activeName: "1",
        idx: 0,
        tbx: 0,
        ids: -1,
        tabListNew: [
          {
            img1: require("@/assets/newSite/crm/p1.png"),
            tit: "线索信息",
          },
          {
            img1: require("@/assets/newSite/crm/p2.png"),
            tit: "客户管理",
          },
          {
            img1: require("@/assets/newSite/crm/p3.png"),
            tit: "商机管理",
          },
          {
            img1: require("@/assets/newSite/crm/p4.png"),
            tit: "销售报价",
          },
          {
            img1: require("@/assets/newSite/crm/p5.png"),
            tit: "销售合同",
          },
          {
            img1: require("@/assets/newSite/crm/p6.png"),
            tit: "客户订单",
          },
          {
            img1: require("@/assets/newSite/crm/p7.png"),
            tit: "开票申请",
          },
          {
            img1: require("@/assets/newSite/crm/p8.png"),
            tit: "回款计划",
          },
          {
            img1: require("@/assets/newSite/crm/p9.png"),
            tit: "销售报表",
          },
        ],
  
        swiperOption: {
          slidesPerView: 3,
          spaceBetween: 30,
          freeMode: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        },
        baseImgUrl:'https://admin.veiban.com',
      caseList:[],
      caseA:{},
      caseB:{},
      caseC:{},
      };
    },
    components: {
      Swiper,
      SwiperSlide,
    },
    created() {
    this.getCase();
  },
    methods: {
      handleClickChild(){
     this.$refs.mofooter.handleDeal(2)
    },
     //成功案例
    getCase() {
      Newslist(
        Qs.stringify({ categoryId: '1839482945283117058', pageSize: 10, pageNo: 1,productCode:'crm' })
      ).then((res) => {
        if (res.data.rows.length) {
            this.caseA = res.data.rows [0]
            this.caseB = res.data.rows [1]
            this.caseC = res.data.rows [2]
            this.caseList =  res.data.rows
        }
      });
    },
    openNewWindow(path) {
      const routeData = this.$router.resolve({path,id:'123456'});
      window.open(routeData.href, "_blank");
    },
      handleSwitch(idx) {
        this.idx = idx;
      },
      handleTest() {
        window.open("https://apipecloud.veiban.com/login");
      },
      handleClick(tab, event) {
        console.log(tab, event);
      },
      handleTab(index) {
        this.tbx = index;
      },
    },
    destroyed() {
      window.removeEventListener("scroll", this.onScroll, false);
    },
  };
  </script>
            
    <style lang="less" scoped>
  .apipe-cloud-course-container-madeSolution {
    .pc-banner {
      height: 600px;
      background-image: url("../../assets/newSite/crm/banner.png");
      background-size: cover;
      background-repeat: no-repeat;
      .container {
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        .tit {
          font-size: 18px;
          margin: 4px 0;
        }
        .exp {
          margin-top: 2rem;
          font-size: 14px;
          border: 1px solid white;
          display: inline-block;
          width: 100px;
          padding: 2px 0;
          text-align: center;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .mobile-banner {
      height: 300px;
      width: 100%;
      background-image: url("../../assets/newSite/crm/banner.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      .container {
        color: white;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        .title {
          font-size: 35px;
        text-align: left;
        color: white;
        font-family:Source Han Sans CN;
        font-weight:200;
        margin-bottom: 30px;
        }
        .titwrap{
        padding: 30px 20px;
        .tit {
          font-size: 13px;
          margin: 5px 0;
        }        
      }
        .exp {
          margin-top: 0.5rem;
          font-size: 12px;
          border: 1px solid white;
          display: inline-block;
          width: 80px;
          text-align: center;
        }
      }
    }
    .pc-industry {
      margin: 80px 0;
      .pro-tit {
        margin-bottom: 40px;
        font-size: 36px;
      }
      .col {
        .p-3 {
          background: #f6faff;
          height: 240px;
          width: 300px;
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
        }
      }
      img {
        width: 100px;
        height: 100px;
      }
      h6 {
        margin-top: 0.5rem;
        font-size: 16px;
        font-weight: 700;
        color: rgba(51, 51, 51, 1);
      }
      .des {
        color: #333333;
        font-size: 14px;
      }
    }
    .mobile-industry {
      margin: 40px 0;
      .pro-tit {
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 700;
        color: #333;
      }
      .col {
        .p-3 {
          background: #f6faff;
         margin:10px ;
        }
      }
      img {
        // width: 100px;
        // height: 100px;
      }
      h6 {
        font-family: Source Han Sans CN;
        font-weight: 200;
        color: #333333;
        font-size: 15px;
      }
      .des {
        color: #333333;
        font-size: 14px;
      }
    }
    .pc-pro-power {
      margin: 50px 0;
      .pro-tit {
        margin-bottom: 30px;
      }
      .tabs {
        display: flex;
        .items {
          flex: 25%;
          // height: 120px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          .tit {
            padding: 20px 0 16px 0;
            font-size: 18px;
            font-weight: 700;
          }
          &:hover {
            cursor: pointer;
          }
          img {
            width: 36px;
            height: 36px;
          }
        }
        .active {
          .tit {
            border-bottom: 4px solid rgba(20, 132, 255, 1);
          }
        }
      }
      .tab-cot {
        background: #f6faff;
        padding: 20px;
        .row {
          min-height: 300px;
          padding: 20px 100px;
          .mtit {
            text-align: left;
            font-size: 24px;
            font-weight: 500;
          }
          .mcot {
            text-align: left;
            margin: 20px 0 30px 0;
            color: #333333;
          }
          .lf {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            justify-content: center;
            padding: 10px;
            .btn {
              width: 120px;
              background: #1484ff;
              &:hover {
                background: #66b1ff;
              }
            }
          }
          p {
            color: #666666;
            font-size: 14px;
          }
        }
      }
    }
    .mobile-pro-power {
      margin: 50px 0;
      .pro-tit {
        margin-bottom: 30px;
        font-size: 20px;
        color: #333;
        font-weight: 700;
      }
      .tabs {
        display: flex;
        width: 375px;
        overflow-x: scroll;
        overflow-y: hidden;
        margin: 0 auto;
        .items {
          width: 33%;
          flex-shrink: 0;
          // height: 120px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          &:hover {
            cursor: pointer;
          }
          .tit {
            padding: 20px 0 16px 0;
          font-family: Source Han Sans CN;
          font-weight: 700;
          font-size: 16px;
          }
          img {
            width: 24px;
            height: 24px;
          }
        }
        .active {
          .tit {
            border-bottom: 4px solid rgba(20, 132, 255, 1);
          }
        }
      }
      .tab-cot {
        background: #f6faff;
         padding: 20px 0;
        .row {
          min-height: 300px;
          padding: 10px;
          h4{
          font-family: Source Han Sans CN;
          font-weight: 700;
          color: #333333;
          font-size: 16px;
        }
          .lf {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            justify-content: center;
            padding: 10px;
            .btn {
              width: 100px;
            }
          }
          p {
            color: #666666;
            font-size: 14px;
          }
        }
      }
    }
    .pc-scene {
      margin: 80px 0;
      .pro-tit {
        margin-bottom: 30px;
        font-size: 36px;
      }
      .col {
        .p-3 {
          min-height: 200px;
          padding: 0 !important;
        }
      }
      img {
        width: 100%;
      }
      h6 {
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
      }
    }
    .mobile-scene {
      margin: 0 0;
      .pro-tit {
        margin-bottom: 30px;
        font-size: 36px;
      }
      .col {
        .p-3 {
 
          padding: 5px !important;
        }
      }
      img {
        width: 100%;
 
      }
      h6 {
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
      }
    }

    .pc-product {
      .acticv {
        span {
          height: 4px;
          border-bottom: 4px solid #1484ff;
          color: #1484ff;
        }
      }
      .col {
        cursor: pointer;
        span {
          display: inline-block;
          height: 40px;
        }
      }
      .pro-wrap {
        background: #f6faff;
        text-align: left;
        .container {
          display: flex;
  
          .cot {
            background: url("../../assets/newSite/home/pc.png");
            background-repeat: no-repeat;
            background-size: cover;
            height: 702px;
            width: 100%;
          }
          img {
            // height: 16rem;
          }
          .content {
            margin: 40px 0;
            width: 100%;
            .wrap {
              flex: 60%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              p {
                font-family: Source Han Sans CN;
                font-weight: 200;
                color: #333333;
                width: 540px;
                margin-bottom: 20px;
                margin-top: 12px;
                font-size: 18px;
              }
            }
            .btn {
              width: 120px;
              background: #1484ff;
              height: 36px;
            }
          }
        }
      }
    }
    .mobile-product {
      .acticv {
        span {
          height: 4px;
          border-bottom: 4px solid #1484ff;
          color: #1484ff;
        }
      }
      .col {
        padding: 1rem 0 0 0;
        cursor: pointer;
        span {
          display: inline-block;
          height: 40px;
          font-family: Source Han Sans CN;
          font-weight: 700;
          font-size: 16px;
        }
      }
      .pro-tit {
        font-family: Source Han Sans CN;
        font-weight: 700;
        color: #333333;
        font-size: 20px;
        margin-bottom: 15px;
      }
      .pro-wrap {
        text-align: left;
        background: #f6faff;
        .container {
          display: flex;
          .content {
            padding: 2rem 1.5rem 1.5rem 1.5rem;
            width: 100%;
            img {
              width: 100%;
              // height: 20rem;
            }
            .p-content {
              color: #666;
              font-size: 0.8rem;
              font-family: "思源黑体";
              line-height: 1.6rem;
            }
            .btn {
              width: 120px;
              background: #1484ff;
              color: white;
            }
          }
        }
      }
    }
  
   
  
   
  }
  </style>
            